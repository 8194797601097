import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Components
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/seo';

const hasWindow = typeof window !== 'undefined';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  useEffect(() => {
    if (hasWindow) {
      document.body.classList.add('daisy');
    }
  }, [hasWindow]);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Oopsy-daisy!" />
      <h1>Oopsy-daisy!</h1>
      <p className="Daisy__text">You've just hit a route that doesn&#39;t exist. What sadness.</p>
    </Layout >
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
